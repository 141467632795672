import React,{ useEffect } from 'react';
import { motion } from 'framer-motion'
import Particles from 'particles-bg'
// Components
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Certificates from '../components/Certificates'
import Footer from '../components/Footer'
// Images
import AchievementsHeader from '../images/AchievementsHeader.png'
import HackerRankLogo from '../images/HackerRankLogo.png'
// Icons
import CodeChiefLogo from '../icons/CodeChiefLogo.png'
import credentials from '../icons/credentials.png'
import star from '../icons/star.svg'
import CHackerrank from '../icons/CHackerrank.png'
import daysofCodeHackerrank from '../icons/30daysofCodeHackerrank.png'
import PythonHackerrank from '../icons/PythonHackerrank.png'
import CPPhackerrank from '../icons/CPPhackerrank.png'
import PShackerrank from '../icons/PShackerrank.png'
import LeetcodeLogo from '../icons/LeetcodeLogo.png'
import DataStructure from '../icons/DataStructure.png'
import Algo2 from '../icons/algo2.png'
import January from '../icons/january.png'
// JSON
import resumeDetails from '../JsonData/resumeDetails'
// CSS
import '../css/Achievements.css'


const box = {
    initial: {
        height: "100vh",
        bottom: 0,
      },
      animate: {
        height: 0,
        transition: {
          when: "afterChildren",
          duration: 1.5,
          ease: [0.87, 0, 0.13, 1],
        },
      },
};
const textContainer = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: {
        duration: 0.25,
        when: "afterChildren",
      },
    },
  };
  const text = {
    initial: {
      y: 40,
    },
    animate: {
      y: 80,
      transition: {
        duration: 1.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
  };


const Achievements = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div>
        <motion.div
        className="absolute z-50 flex items-center justify-center w-full bg-black"
        initial="initial"
        animate="animate"
        variants={box}
      >
          <motion.svg variants={textContainer}  className="absolute z-50 flex">
            <pattern
              id="pattern"
              patternUnits="userSpaceOnUse"
              width={1000}
              height={900}
              className="text-white"
            >
              <rect className="w-full h-full fill-current" />
              <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
            </pattern>
            <text
              className="text-3xl font-bold"
              text-anchor="middle"
              x="50%"
              y="50%"
              style={{ fill: "url(#pattern)" }}
            >
            &#64;Ankit Archive 
            </text>        
            
      </motion.svg>
      </motion.div>
            <Sidebar />
            <div className="home-section pb-20">
                <Navbar />
                <Particles color="#48CAE4" num={300} type="cobweb" bg={true} />

                {/* HEADING SECTION */}
                <div className="achivements-header flex flex-col-reverse md:flex-row mt-10 md:mt-0 px-5 md:px-0">
                    <div className="achivements-Heading-text md:w-1/2 flex md:ml-20 items-center">“ Perfection is achieved not when there is nothing more to add, but rather when there is nothing more to take away ! ! ! ”</div>
                    <div className="achivements-Heading-image md:w-1/2 flex justify-center items-center">
                        <img src={AchievementsHeader} alt="AchievementsHeader" />
                    </div>
                </div>

                {/* BODY SECTION */}
                <div className="achivements-Title-Heading text-center mb-10 uppercase">Achievements</div>

                {/* COMPETETIVE PROGRAMMING ACHIVEMENT */}
                <div className="flex flex-col md:flex-row justify-center items-center md:space-between md:mx-28 mb-16" style={{color:"#979797"}}>
                    {/* HACKERRANK CARD */}
                    <div className="competetive-card">
                        <div className="flex flex-row items-center">
                            <img src={HackerRankLogo} alt="HackerRankLogo" style={{width:"40px",height:"40px"}} />
                            <a href="https://www.hackerrank.com/AnkitRajCodes" target="_blank" rel="noreferrer"><span className="text-lg font-semibold ml-5">HackerRank</span></a>
                        </div>

                        <p  className="text-lg font-semibold mt-5 mb-2">Badges</p>
                        <div className="flex flex-row ">
                            <img src={CHackerrank} alt="CHackerrank" style={{width:"55px",height:"55px"}} />
                            <img src={daysofCodeHackerrank} alt="30daysofCodeHackerrank" className="ml-3" style={{width:"55px",height:"55px"}} />
                            <img src={PythonHackerrank} alt="PythonHackerrank" className="ml-3" style={{width:"55px",height:"55px"}} />
                            <img src={CPPhackerrank} alt="CPPhackerrank" className="ml-3" style={{width:"55px",height:"55px"}} />
                            <img src={PShackerrank} alt="PShackerrank" className="ml-3" style={{width:"55px",height:"55px"}} />
                        </div>

                        <p  className="text-lg font-semibold mt-5 mb-2">Certificates</p>
                        <a href="https://www.hackerrank.com/certificates/a8ff6364e742" className="text-lg flex flex-row items-center" target="_blank" rel="noreferrer">
                            <img src={credentials} alt="credentials" style={{width:"20px",height:"20px"}} />
                            <span className="text-base ml-2">Problem Solving (Basic) Certificate</span>
                        </a>
                        <a href="https://www.hackerrank.com/certificates/6dc95524000f" className="text-lg flex flex-row items-center mt-2" target="_blank" rel="noreferrer">
                            <img src={credentials} alt="credentials" style={{width:"20px",height:"20px"}} />
                            <span className="text-base ml-2">Cascading Style Sheets Certificate</span>
                        </a>
                    </div>

                    {/* LEETCODE CARD */}
                    <div className="competetive-card mt-5 md:mt-0 md:mx-10">
                        <div className="flex flex-row items-center">
                            <img src={LeetcodeLogo} alt="LeetcodeLogo" style={{width:"40px",height:"40px"}} />
                            <div className="flex flex-col ml-5">
                                <a href="https://leetcode.com/DjAnkitRajCode/" target="_blank" rel="noreferrer"><span className="text-lg font-semibold ">Leetcode</span></a>
                                <div className="flex flex-row">
                                    <img src={star} alt="star" style={{width:"15px",height:"15px"}} />
                                    <img src={star} alt="star" className="ml-1" style={{width:"15px",height:"15px"}} />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row justify-around items-center my-3">
                            <div className="flex flex-row items-center text-3xl">
                                2
                                <img src={star} alt="star" className="ml-1" style={{width:"15px",height:"15px"}} />
                            </div>

                            <div className="Divide-Line"></div>

                            <div className="flex flex-col">
                                <span className="text-lg mb-2">Highest Rating</span>
                                <div className="flex flex-row justify-center text-black font-semibold">
                                    <div className="Rating" >0</div>
                                    <div className="Rating ml-2">0</div>
                                    <div className="Rating ml-2">0</div>
                                    <div className="Rating ml-2">0</div>
                                </div>
                            </div>
                        </div>

                        <p  className="text-lg font-semibold mt-2 mb-2 ">Badges</p>
                        <div className="flex flex-row ">
                            <img src={DataStructure} alt="Badge" style={{width:"50px",height:"50px"}} />
                            <img src={Algo2} alt="Badge" className="ml-3" style={{width:"50px",height:"50px"}} />
                            <img src={January} alt="Badge" className="ml-3" style={{width:"50px",height:"50px"}} />
                        </div>

                        <p className="text-lg text-center font-semibold mt-5 ">120+ Question Solved</p>

                    </div>

                    {/* CODECHIEF CARD */}
                    
                        <div className="competetive-card mt-5 md:mt-0 ">
                        <div className="flex flex-row items-center">
                            <img src={CodeChiefLogo} alt="CodeChiefLogo" style={{width:"40px",height:"40px"}} />
                            <div className="flex flex-col ml-5">
                                <a href="https://www.codechef.com/users/ankitrajcode" target="_blank" rel="noreferrer" className="text-lg font-semibold ">Codechief</a>
                                <div className="flex flex-row">
                                    <img src={star} alt="star" style={{width:"15px",height:"15px"}} />
                                    <img src={star} alt="star" className="ml-1" style={{width:"15px",height:"15px"}} />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row justify-around items-center my-3">
                            <div className="flex flex-row items-center text-3xl">
                                2
                                <img src={star} alt="star" className="ml-1" style={{width:"15px",height:"15px"}} />
                            </div>

                            <div className="Divide-Line"></div>

                            <div className="flex flex-col">
                                <span className="text-lg mb-2">Highest Rating</span>
                                <div className="flex flex-row justify-center text-black font-semibold">
                                    <div className="Rating" >1</div>
                                    <div className="Rating ml-2">5</div>
                                    <div className="Rating ml-2">0</div>
                                    <div className="Rating ml-2">4</div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row justify-around items-center mt-8">
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-center text-black font-semibold">
                                    <div className="Rating" >1</div>
                                    <div className="Rating ml-2">3</div>
                                    <div className="Rating ml-2">5</div>
                                    <div className="Rating ml-2">8</div>
                                    <div className="Rating ml-2">2</div>
                                    <div className="Rating ml-2">4</div>
                                </div>
                                <span className="text-base mt-2 text-center">Global Rating</span>
                            </div>

                            <div className="Divide-Line" className="mx-3" style={{height:"60px"}}></div>


                            <div className="flex flex-col">
                                <div className="flex flex-row justify-center text-black font-semibold">
                                    <div className="Rating" >1</div>
                                    <div className="Rating ml-2">6</div>
                                    <div className="Rating ml-2">4</div>
                                    <div className="Rating ml-2">2</div>
                                    <div className="Rating ml-2">2</div>
                                    <div className="Rating ml-2">1</div>
                                </div>
                                <span className="text-base mt-2 text-center">Country Rating</span>
                            </div>
                        </div>

                        <p className="text-lg text-center font-semibold mt-5">10+ Question Solved</p>

                    </div>
                    
                </div>

                <div className="achivements-Title-Heading text-center mb-10 uppercase">Certifications</div>
                
                
                {resumeDetails.certifications.map((certificates) =>{
                    return(
                        <>
                        <Certificates 
                        imgUrl = {certificates.imgUrl}
                        title={certificates.title}
                        organization={certificates.organization}
                        date={certificates.date}
                        link={certificates.link}       
                        /> 
                        </>
                    )
                })}              

            </div>
            <Footer />
        </div>
    )
}

export default Achievements