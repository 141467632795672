import React,{ useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
// Component
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// Icons
import profile from '../icons/profile.svg'
import Calander from '../icons/Calander.svg'
// CSS
import '../css/SeePost.css'

const box = {
    initial: {
        height: "100vh",
        bottom: 0,
      },
      animate: {
        height: 0,
        transition: {
          when: "afterChildren",
          duration: 1.5,
          ease: [0.87, 0, 0.13, 1],
        },
      },
};
const textContainer = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: {
        duration: 0.25,
        when: "afterChildren",
      },
    },
  };
  const text = {
    initial: {
      y: 40,
    },
    animate: {
      y: 80,
      transition: {
        duration: 1.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
  };

export default function SeePost() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
    return (
        <div>
        <motion.div
        className="absolute z-50 flex items-center justify-center w-full bg-black"
        initial="initial"
        animate="animate"
        variants={box}
      >
          <motion.svg variants={textContainer}  className="absolute z-50 flex">
            <pattern
              id="pattern"
              patternUnits="userSpaceOnUse"
              width={750}
              height={800}
              className="text-white"
            >
              <rect className="w-full h-full fill-current" />
              <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
            </pattern>
            <text
              className="text-4xl font-bold"
              text-anchor="middle"
              x="50%"
              y="50%"
              style={{ fill: "url(#pattern)" }}
            >
            &#64;Ankit Blog
            </text>        
            
      </motion.svg>
      </motion.div>
      <Sidebar />
            <div className="home-section">
              <Navbar />
              <div className="py-20 px-5 md:px-36" style={{ color: "#9A9A9A" }}>
                <img src={"https://miro.medium.com/max/1400/0*AMMQQpAwMnZzrdpW"} alt="BlogImage" style={{width:"100%",borderRadius:"20px"}} />

                  <div className="Blog-Body -mt-2 md:-mt-20 md:p-20 md:mx-10">
                    <div className="Blog-Title pt-5 md:pt-0 mb-10">
                      Frontend Developers should learn Firebase?
                    </div>

                    <div className="flex flex-col md:flex-row items-center text-lg font-semibold">
                        <div className="md:w-1/2 flex flex-row items-center justify-end md:mr-3"><img src={profile} alt="profile" style={{ width: "20px" }} /> <span className="ml-3">Ankit Raj</span> </div> <span className="hidden md:block">|</span>
                        <div className="md:w-1/2 flex flex-row items-center md:ml-3 mt-5 md:mt-0"><img src={Calander} alt="Calander" style={{ width: "20px" }} /> <span className="ml-3">Date - 12th Nov, 2021</span> </div>
                    </div>

                      <div className="px-5 md:px-16 text-justify my-10">
                        <h1 className="font-bold text-xl md:text-2xl text-white">Introduction</h1>
                        <h1 className="font-bold text-xl md:text-2xl mt-5">What is Firebase?</h1>
                        <div className="mb-3 leading-10 text-lg">
                          <p className="pb-3">Firebase is an online, free service provided by Google which acts as a very feature-rich, fully-fledged back-end to both mobile and web applications.Frontend Developers can use Firebase to store and retrieve data to and from a NoSQL database called Firestore, as well as to authenticate their app's users with the Firebase Auth service.</p>
                          <p>While Firestore, the Realtime Database is really just one big JSON object that the developers can manage in realtime. It allows you to set up the data you want for your application, while Firebase Authentication is built by the same people who created Google Sign-in, Smart Lock, and Chrome Password Manager.Firebase also provides a service called Firebase Cloud Functions which allows you to run server-side JavaScript code in a Node.js environment, and you can also deploy all of your applications to Firebase hosting.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">10 Essential Firebase Services for Frontend Developers?</h1>
                        <div className="mt-3 leading-10 text-lg ">
                          <p>Firebase services than I have mentioned here and you can basically divide them into two categories like Development and Testing Services and Analytics services.Here is a list of some of the most popular Development and Testing Firebase Services:</p>
                        </div>
                        <div className="mb-3 leading-10 text-lg ">
                          • Realtime Database <br/>
                          • Auth <br/>
                          • Test Lab <br/>
                          • Crashlytics <br/>
                          • Cloud Functions <br/>
                          • Firestore <br/>
                          • Cloud Storage <br/>
                          • Performance Monitoring <br/>
                          • Crash Reporting <br/>
                          • Hosting <br/>
                          • Grow & Engage your audience                            
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Why Frontend Developers should learn Firebase</h1>
                        <h1 className="font-bold text-xl md:text-2xl mt-5">1. Unblocks Frontend Development</h1>
                        <div className="mb-3 leading-10 text-lg">
                          <p className="pb-3">Firebase provides a ready-made backend system that frontend developer can use to hook their GUI without waiting for the backend to be ready.</p>
                        </div>
                        <h1 className="font-bold text-xl md:text-2xl mt-5">2. Faster Development</h1>
                        <div className="mb-3 leading-10 text-lg">
                          <p className="pb-3">Firebase provides database, authentication, payment, and API which are an integral part of any frontend application, and with that is made readily available, your development time is significantly reduced.</p>
                          <p>Though a good knowledge of Firebase services helps here and if you are starting from scratch then the Firebase Fundamentals course by Joe Eames on Pluralsight is a great resource for beginners.</p>
                        </div>
                        <h1 className="font-bold text-xl md:text-2xl mt-5">3. Better Code</h1>
                        <div className="mb-3 leading-10 text-lg">
                          <p className="pb-3">People might argue that using Firebase means you are locked into Google Cloud Platform and you may not be able to deploy your web application or mobile app into AWS, Azure, GCP, or any other Cloud platform, but that's not true.As long as you follow standard coding practice and separation of concern you can encapsulate interaction in the service or data layer.</p>
                          <p>In most cases, developers use Firebase on the development stage with an actual backend on production. So they design their app in such a way that switching to a different backend is easier, this approach results in a better structure.</p>
                        </div>
                        <h1 className="font-bold text-xl md:text-2xl mt-5">4. Speed and Simplicity</h1>
                        <div className="mb-3 leading-10 text-lg">
                          <p className="pb-3">Firebase not only provides blazing-fast data storage capabilities but also a simple API, which can be tempting if you are considering to use Firebase in Production.That's all about why frontend developers should learn Firebase in 2022. These were just some of the most important reasons I can think of now, but there are many more. Firebase is evolving and adding more and more services so that more and more companies start using them in Production as well.</p>
                          <p>If you are learning Angular, React.js, or Vue.js then Firebase can really help you with developing projects and mastering the front-end framework of your choice.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10 text-center pb-10 md:pb-0">Thank You For Reading</h1>
                      </div>
                    </div>
                </div>

                {/* Other BLOG */}
                <div className="flex flex-col md:flex-row px-5 md:px-36 my-20">
                  <Link to="/blogs/web-accessibility" className="md:w-1/2">
                    <img src={"https://altitudemarketing.com/wp-content/uploads/2019/11/coding-graphic-min.png"} alt="BlogImage" style={{ width: "550px" , borderRadius: "31px"}} />
                  </Link>
                  <div className="md:w-1/2 flex flex-col justify-between md:ml-10" style={{ color: "#9A9A9A" }}>
                    <div className="text-lg mt-5 md:mt-0">Date : 21st jan , 2021</div>
                    <Link to="/blogs/web-accessibility"><div className="text-2xl font-semibold my-2">A Beginner's Guide to Web Accessibility</div></Link>
                    <div className="text-lg my-2 text-justify">Web accessibility means that websites, tools, and technologies are designed and developed so that people with disabilities can use themMore specifically, people can perceive, understand, navigate, and interact with the Web contribute to the Web...</div>
                    <Link to="/blogs/web-accessibility" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
                  </div>
                </div>

                {/* FOOTER */}
                <Footer />
            </div>
        </div>
    )
}