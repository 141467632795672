import React,{ useEffect } from 'react';
// NPM Packages
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
// Components
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Particles from 'particles-bg'
import Footer from '../components/Footer'
// CSS
import '../css/Blogs.css'

const box = {
  initial: {
    height: "100vh",
    bottom: 0,
  },
  animate: {
    height: 0,
    transition: {
      when: "afterChildren",
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};
const textContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: 0.25,
      when: "afterChildren",
    },
  },
};
const text = {
  initial: {
    y: 40,
  },
  animate: {
    y: 80,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

export default function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <motion.div
        className="absolute z-50 flex items-center justify-center w-full bg-black"
        initial="initial"
        animate="animate"
        variants={box}
      >
        <motion.svg variants={textContainer} className="absolute z-50 flex">
          <pattern
            id="pattern"
            patternUnits="userSpaceOnUse"
            width={900}
            height={1000}
            className="text-white"
          >
            <rect className="w-full h-full fill-current" />
            <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
          </pattern>
          <text
            className="text-4xl font-bold"
            text-anchor="middle"
            x="50%"
            y="50%"
            style={{ fill: "url(#pattern)" }}
          >
            &#64;Ankit Blogs
          </text>

        </motion.svg>
      </motion.div>
      <Sidebar />
      <div className="home-section pb-20">
        <Navbar />
        <Particles color="#48CAE4" num={200} type="cobweb" bg={true} />
        {/* HEADING SECTION */}
        <div className="blogs-header flex flex-row">

          <div className="blogs-Heading-text flex ml-10 md:ml-20 items-center">THE <br className=" hidden md:block" /> BLOGS</div>

        </div>

        <div className="flex flex-col md:flex-row px-5 md:px-36">
          <Link to="/blogs/web-accessibility" className="md:w-1/2">
            <img src={"https://altitudemarketing.com/wp-content/uploads/2019/11/coding-graphic-min.png"} alt="BlogImage" style={{ width: "550px" , borderRadius: "31px"}} />
          </Link>
          <div className="md:w-1/2 flex flex-col justify-between md:ml-10" style={{ color: "#9A9A9A" }}>
            <div className="text-lg mt-5 md:mt-0">Date : 1st Nov , 2021</div>
            <Link to="/blogs/web-accessibility"><div className="text-2xl font-semibold my-2">A Beginner's Guide to Web Accessibility</div></Link>
            <div className="text-lg my-2 text-justify">Web accessibility means that websites, tools, and technologies are designed and developed so that people with disabilities can use themMore specifically, people can perceive, understand, navigate, and interact with the Web contribute to the Web...</div>

            <Link to="/blogs/web-accessibility" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
          </div>

        </div>

        <div className="px-5 md:px-36 flex flex-col md:flex-row my-16 md:my-28">

          <div className="md:w-1/2 flex flex-col" >
            <Link to="/blogs/frontend-developers-should-learn-firebase">
              <img src={"https://miro.medium.com/max/1400/0*0clHP-cI3SPOJp2r.jpg "} alt="BlogImage2" style={{ width: "550px", height:"320px" }} />
            </Link>
            <div className=" flex flex-col justify-between " style={{ color: "#9A9A9A" }}>
              <div className="text-lg mt-5">Date - 12th Nov , 2021</div>
              <Link to="/blogs/frontend-developers-should-learn-firebase" className="text-2xl font-semibold my-2">Frontend Developers should learn Firebase?</Link>
              <div className="text-lg my-2 text-justify md:mr-10">Firebase is a free service provided by Google which can be used as a feature-rich back-end. Frontend developers can use Firebase to decrease development time and increase productivity.If you are a frontend developer which often gets stuck because the backend and continue...</div>
              <Link to="/blogs/frontend-developers-should-learn-firebase" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
            </div>
          </div>

          <div className="md:w-1/2 flex flex-col rounded-xl md:ml-8 mt-16 md:mt-0" >
            <Link to="/blogs/jwt">
              <img src={"https://miro.medium.com/max/1600/1*dM5P75FLQnWxxVLze35NCg.jpeg"} alt="BlogImage2" style={{ width: "550px",  height:"320px"}} />
            </Link>
            <div className="flex flex-col justify-between " style={{ color: "#9A9A9A" }}>
              <div className="text-lg mt-5">Date - 21st Nov , 2021</div>
              <Link to="/blogs/jwt" className="text-2xl font-semibold my-2">Authentication API with JWT Token </Link>
              <div className="text-lg my-2 text-justify ">Authentication and authorization are used in security, particularly when it comes to getting access to a system. Yet, there is a significant distinction between gaining entry into a house(authentication) and what you can do while inside authorization... </div>
              <Link to="/blogs/jwt" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
            </div>
          </div>

        </div>

        <div className="flex flex-col md:flex-row px-5 md:px-36">
          <Link to="/blogs/submit-the-html-form-data-in-google-sheet" className="md:w-1/2">
            <img src={"https://letscodee.com/wp-content/uploads/2021/06/html_to_drive-1536x864.png"} alt="BlogImage" style={{ width: "550px" }} />
          </Link>
          <div className="md:w-1/2 flex flex-col justify-between" style={{ color: "#9A9A9A" }}>
            <div className="text-lg mt-5 md:mt-0">Date : Coming Soon...</div>
            <Link to="/blogs/submit-the-html-form-data-in-google-sheet" className="text-2xl font-semibold my-2">Submit the HTML form data in Google Sheet</Link>
            <div className="text-lg my-2 text-justify">Send form data to Google Sheets with MightyForms. MightyForms is a powerful form builder designed by users for users. You can build an online form quick and easy with MightyForms drag-and-drop editor while optimizing your workflow automation with custom-built solutions...</div>
            <Link to="/blogs/submit-the-html-form-data-in-google-sheet" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}
