import React,{ useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
// Component
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// Icons
import profile from '../icons/profile.svg'
import Calander from '../icons/Calander.svg'
// Image
import web from '../images/web.jpeg'
// CSS
import '../css/SeePost.css'

const box = {
    initial: {
        height: "100vh",
        bottom: 0,
      },
      animate: {
        height: 0,
        transition: {
          when: "afterChildren",
          duration: 1.5,
          ease: [0.87, 0, 0.13, 1],
        },
      },
};
const textContainer = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: {
        duration: 0.25,
        when: "afterChildren",
      },
    },
  };
  const text = {
    initial: {
      y: 40,
    },
    animate: {
      y: 80,
      transition: {
        duration: 1.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
  };

export default function SeePost() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
    return (
        <div>
        <motion.div
        className="absolute z-50 flex items-center justify-center w-full bg-black"
        initial="initial"
        animate="animate"
        variants={box}
      >
          <motion.svg variants={textContainer}  className="absolute z-50 flex">
            <pattern
              id="pattern"
              patternUnits="userSpaceOnUse"
              width={750}
              height={800}
              className="text-white"
            >
              <rect className="w-full h-full fill-current" />
              <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
            </pattern>
            <text
              className="text-4xl font-bold"
              text-anchor="middle"
              x="50%"
              y="50%"
              style={{ fill: "url(#pattern)" }}
            >
            &#64;Ankit Blog
            </text>        
            
      </motion.svg>
      </motion.div>
            <Sidebar />
            <div className="home-section">
              <Navbar />
              <div className="py-20 px-5 md:px-36" style={{ color: "#9A9A9A" }}>
                <img src={web} alt="BlogImage" style={{width:"100%",borderRadius:"20px"}} />

                  <div className="Blog-Body -mt-2 md:-mt-20 md:p-20 md:mx-10">
                    <div className="Blog-Title pt-5 md:pt-0 mb-10">
                      A Beginner's Guide to Web Accessibility
                    </div>

                    <div className="flex flex-col md:flex-row items-center text-lg font-semibold">
                        <div className="md:w-1/2 flex flex-row items-center justify-end md:mr-3"><img src={profile} alt="profile" style={{ width: "20px" }} /> <span className="ml-3">Ankit Raj</span> </div> <span className="hidden md:block">|</span>
                        <div className="md:w-1/2 flex flex-row items-center md:ml-3 mt-5 md:mt-0"><img src={Calander} alt="Calander" style={{ width: "20px" }} /> <span className="ml-3">Date - 1st Nov, 2021</span> </div>
                    </div>

                      <div className="px-5 md:px-16 text-justify my-10">
                        <h1 className="font-bold text-xl md:text-2xl text-white">Introduction</h1>
                        <div className="my-3 leading-10 text-lg">
                          <p className="pb-3">Welcome to A Beginner's Guide to Web Accessibility. Over the course we'll be looking at web accessibility, what it is, why it matters and what you can do to start making your sites more accessible. This is a great course for anyone interested in or already involved in the web. That includes designers, developers, project managers, and CEOs. We'll review some important terms and organizations involved to get a better idea of the laws behind web accessibility as well as what the future looks like in this area. In chapter 1 we'll learn about different disabilities, how they apply to the web, and how users with those particular disabilities might navigate the web. We'll also take a look at what it means to conform to accessible standards.</p>
                          <p className="pb-3">When websites and web tools are properly designed and coded, people with disabilities can use them. However, currently many sites and tools are developed with accessibility barriers that make them difficult or impossible for some people to use.</p>
                          <p>Making the web accessible benefits individuals, businesses, and society. International web standards define what is needed for accessibility.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Accessibility in Context</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p className="pb-3">The Web is fundamentally designed to work for all people, whatever their hardware, software, language, location, or ability. When the Web meets this goal, it is accessible to people with a diverse range of hearing, movement, sight, and cognitive ability.</p>
                          <p className="pb-3">Thus the impact of disability is radically changed on the Web because the Web removes barriers to communication and interaction that many people face in the physical world. However, when websites, applications, technologies, or tools are badly designed, they can create barriers that exclude people from using the Web.</p>
                          <p >Accessibility is essential for developers and organizations that want to create high-quality websites and web tools, and not exclude people from using their products and services.</p>
                        </div>
                        
                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">What is Web Accessibility</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <h1 className="font-normal text-white mt-5">Web accessibility means that websites, tools, and technologies are designed and developed so that people with disabilities can use them. More specifically, people can:</h1>
                            • perceive, understand, navigate, and interact with the Web <br/>
                            • contribute to the Web
                            <h1 className="font-normal text-white mt-5">Web accessibility encompasses all disabilities that affect access to the Web, including:</h1>
                            • Auditory <br/>
                            • Cognitive <br/>
                            • Neurological <br/>
                            • Physical <br/>
                            • Speech <br/>
                            • Visual
                            <h1 className="font-normal text-white mt-5">Web accessibility encompasses all disabilities that affect access to the Web, including:</h1>
                            • Auditory <br/>
                            • Cognitive <br/>
                            • Neurological <br/>
                            • Physical <br/>
                            • Speech <br/>
                            • Visual
                            <h1 className="font-normal text-white mt-5">Web accessibility also benefits people without disabilities, for example:</h1>
                            • People using mobile phones, smart watches, smart TVs, and other devices with small screens, different input modes, etc. <br/>
                            • Older people with changing abilities due to ageing <br/>
                            • People with “temporary disabilities” such as a broken arm or lost glasses <br/>
                            • People with “situational limitations” such as in bright sunlight or in an environment where they cannot listen to audio <br/>
                            • People using a slow Internet connection, or who have limited or expensive bandwidth <br/>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Evaluating Accessibility</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p className="pb-3">When developing or redesigning a website, evaluate accessibility early and throughout the development process to identify accessibility problems early, when it is easier to address them. Simple steps, such as changing settings in a browser, can help you evaluate some aspects of accessibility. Comprehensive evaluation to determine if a website meets all accessibility guidelines takes more effort.</p>
                          <p >There are evaluation tools that help with evaluation. However, no tool alone can determine if a site meets accessibility guidelines. Knowledgeable human evaluation is required to determine if a site is accessible.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10 text-center pb-10 md:pb-0">Thank You For Reading</h1>
                      </div>
                    </div>
                </div>

                {/* Other BLOG */}
                <div className="flex flex-col md:flex-row px-5 md:px-36 my-20">
                  <Link to="/blogs/jwt" className="md:w-1/2">
                    <img src={"https://miro.medium.com/max/1600/1*dM5P75FLQnWxxVLze35NCg.jpeg"} alt="BlogImage" style={{ width: "550px" , borderRadius: "10px"}} />
                  </Link>
                  <div className="md:w-1/2 flex flex-col justify-between md:ml-10" style={{ color: "#9A9A9A" }}>
                    <div className="text-lg mt-5 md:mt-0">Date : 21st Nov , 2021</div>
                    <Link to="/blogs/jwt"><div className="text-2xl font-semibold my-2">Authentication API with JWT Token</div></Link>
                    <div className="text-lg my-2 text-justify">Authentication and authorization are used in security, particularly when it comes to getting access to a system. Yet, there is a significant distinction between gaining entry into a house(authentication) and what you can do while inside authorization...</div>
                    <Link to="/blogs/jwt" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
                  </div>
                </div>

                {/* FOOTER */}
                <Footer />
            </div>
        </div>
    )
}
