import React from 'react'
import { Link } from 'react-router-dom'
// Component
import Animation from "../components/Animation"
// Images
import Guide from '../images/web.jpeg'
import Firebase from '../images/Firebase.jpg'
import JWT from '../images/JWT.jpeg'
// CSS
import '../css/Blogs.css'

export default function BlogsLanding() {
    return (
        <div className="px-5 md:px-24 pb-16 md:py-16">

            <div className="Landing-Heading text-center mb-10">BLOGS</div>

            <div>
                <div className="flex flex-row justify-center">
                    <Link to ="/blogs/web-accessibility" className="blogMaze " style={{backgroundImage:`url(${Guide})`}}></Link>
                    <Link to ="/blogs/jwt" className="blogMaze ml-2 md:ml-3" style={{backgroundImage:`url(${JWT})` , backgroundSize:`cover`}}></Link>
                </div>
                <div className="flex flex-row justify-center -mt-8 md:-mt-16">
                    <Link to ="/blogs/frontend-developers-should-learn-firebase" className="blogMaze" style={{backgroundImage:`url(${Firebase})`}}></Link>
                </div>
            </div>
            <Link to="/blogs" >
                <Animation />
            </Link>
        </div>
    )
}
