import React from 'react'
import { Link } from 'react-router-dom'
// JSON
import resumeDetails from '../JsonData/resumeDetails'
// Components
import Animation from "../components/Animation"
// CSS
import '../css/Projects.css'


export default function ProjectLanding() {
    return (
        <div className="px-2 md:px-24 py-16">

            <div className="Landing-Heading text-center mb-10">PROJECTS</div>

            <div className="flex flex-col md:flex-row ">
                <div className="md:w-1/2 flex flex-col items-center md:items-end -mt-5">
                    {resumeDetails.projectHome.map((project) => {
                        return (
                            <>
                                <Link to = '/projects' className="projectCard flex flex-row items-end mt-5" style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${project.imgUrl})`}} >

                                    <div className="w-1/2 text-white">{project.title} </div>
                                    <div className="w-1/2 flex flex-row justify-end mb-4">
                                        <img src={project.techStack1} alt="techStack" className="mr-3" />
                                        <img src={project.techStack2} alt="techStack" className="mr-3" />
                                        <img src={project.techStack3} alt="techStack" className="mr-3" />
                                        {(project.techStack4) || (project.techStack5) ? (
                                            <>
                                                <img src={project.techStack4} alt="techStack" className="mr-3" style={{width:"70px"}} />
                                            </>
                                        ) : null}
                                    </div>
                                </Link>
                            </>
                        )
                    })}

                </div>
                <div className="mt-4 md:mt-0 md:w-1/2">
                    {resumeDetails.longProjectCardHome.map((project) => {
                        return (
                            <>
                                <Link to='/projects'  className="projectCard-long flex flex-row items-end ml-4" style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${project.imgUrl})`}}>
                                    <div className="w-1/2 lg text-white">{project.title}</div>
                                    <div className="w-1/2 flex flex-row justify-end mb-4">
                                        <img src={project.techStack1} alt="techStack" className="mr-3" />
                                        <img src={project.techStack2} alt="techStack" className="mr-3" />
                                        <img src={project.techStack3} alt="techStack"  />
                                    </div>
                                </Link>
                            </>
                        )
                    })}

                </div>
            </div>

            <Link to="/projects" >
                <Animation />
            </Link>
        </div>
    )
}
