import React from 'react'
import react from "../icons/react.png"
import javaScript from "../icons/javascript.gif"
import expressjs from "../icons/expressjs.png"
import next from "../icons/next.png"
import bootstrap from "../icons/bootstrap.png"
import sass from "../icons/sass.png"
import Tailwindcss from "../icons/tailwindcss.svg";
import wordpress from "../icons/wordpress.gif";
import Nodejs from "../icons/nodejs.png"
import firebase from "../icons/firebase.png"
import mysql from "../icons/mysql.png"
import mongodb from "../icons/mongodb.png"
import redux from "../icons/redux.svg"
import c from "../icons/c.png"
import CPlus from "../icons/CPlus.png"
import python from "../icons/python.gif"
import adobeXD from "../icons/adobeXD.png"
import figma from "../icons/figma.png"
import Java from "../icons/java.gif"
import PHP from "../icons/php.svg"
import photoshop from "../icons/photoshop.svg"
import illustrator from "../icons/illustrator.gif"
import afterEffect from "../icons/after-effects.gif"

export default function TechAndTools() {
    return (
        <div className="px-5 md:px-24 py-16">

            <div className="Landing-Heading TechAndTools-Heading text-center mb-36 md:mb-10">TECH <br className="md:hidden" /> & <br className="md:hidden" /> TOOLS</div>
            
            <div className="flex flex-col md:flex-row mt-20">
                <div className="md:w-1/2 TechAndTools-SubHeading">FRONT-END</div>
                <div className="md:w-1/2 flex flex-col justify-center">
                    <div className="flex flex-row justify-center">
                        <div className="maze" style={{backgroundImage:`url(${next})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${react})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${sass})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${Tailwindcss})`}}></div>
                    </div>
                    <div className="flex flex-row justify-center -mt-5">
                        <div className="maze mt-1 md:mt-0" style={{backgroundImage:`url(${bootstrap})`}}></div>
                        <div className="maze mt-1 md:mt-0 ml-0.5 md:ml-1" style={{backgroundImage:`url(${javaScript})`}}></div>
                        <div className="maze mt-1 md:mt-0 ml-0.5 md:ml-1" style={{backgroundImage:`url(${wordpress})`}}></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse my-20 md:my-40">
                <div className="md:w-1/2 TechAndTools-SubHeading">BACK-END</div>
                <div className="md:w-1/2 flex flex-col justify-center">
                    <div className="flex flex-row justify-center">
                        <div className="maze" style={{backgroundImage:`url(${Nodejs})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${expressjs})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${redux})`}}></div>                        
                    </div>
                    <div className="flex flex-row justify-center -mt-5">
                        <div className="maze mt-1 md:mt-0" style={{backgroundImage:`url(${firebase})`}}></div>
                        <div className="maze mt-1 md:mt-0 ml-0.5 md:ml-1" style={{backgroundImage:`url(${PHP})`}}></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 TechAndTools-SubHeading">DATABASES</div>
                <div className="md:w-1/2 flex flex-col justify-center">
                    <div className="flex flex-row justify-center">
                        <div className="maze" style={{backgroundImage:`url(${firebase})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${mongodb})`}}></div>
                    </div>
                    <div className="flex flex-row justify-center -mt-5">
                        <div className="maze mt-1 md:mt-0" style={{backgroundImage:`url(${mysql})`}}></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse my-20 md:my-40">
                <div className="md:w-1/2 TechAndTools-SubHeading">LANGUAGES</div>
                <div className="md:w-1/2 flex flex-col justify-center">
                    <div className="flex flex-row justify-center">
                        <div className="maze" style={{backgroundImage:`url(${c})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${CPlus})`}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${javaScript})`}}></div>
                    </div>
                    <div className="flex flex-row justify-center -mt-5">
                        <div className="maze mt-1 md:mt-0" style={{backgroundImage:`url(${Java})`}}></div>
                        <div className="maze mt-1 md:mt-0 ml-0.5 md:ml-1" style={{backgroundImage:`url(${python})`}}></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 TechAndTools-SubHeading">GRAPHICS</div>
                <div className="md:w-1/2 flex flex-col justify-center">
                    <div className="flex flex-row justify-center">
                        <div className="maze" style={{backgroundImage:`url(${adobeXD})`,backgroundSize:"50px"}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${figma})`,backgroundSize:"60px"}}></div>
                        <div className="maze ml-0.5 md:ml-1" style={{backgroundImage:`url(${photoshop})`,backgroundSize:"60px"}}></div>
                    </div>
                    <div className="flex flex-row justify-center -mt-5">
                    <div className="maze mt-1 md:mt-0" style={{backgroundImage:`url(${illustrator})`,backgroundSize:"60px"}}></div>
                    <div className="maze mt-1 md:mt-0 ml-0.5 md:ml-1" style={{backgroundImage:`url(${afterEffect})`,backgroundSize:"60px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
