import React , { useEffect , useRef } from 'react'
import { motion } from 'framer-motion'
import Particles from 'particles-bg'
import lottie from 'lottie-web'
// Components
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// CSS
import '../css/Projects.css'
// ICONS
import javaScript from "../icons/javaScript.svg"
import redux from "../icons/redux.svg"
import cross from "../icons/cross.svg"
import css from "../icons/css.svg"
import react from '../icons/react.png'
import analytics from '../icons/analytics.png'
import bootstrap from "../icons/bootstrap2.png"
import Wordpress from "../icons/Wordpress.png"
import Php from "../icons/php.png"
import Kotlin from "../icons/kotlin.png"
import Firebase from "../icons/firebase.png"
import Figma from "../icons/figma.png"
import Tailwind from "../icons/tailwindcss.svg"
import java from '../icons/java.png'
import Python   from   '../icons/python.png'
import Jupyter from "../icons/jupyter.svg";
import Tensorflow from  "../icons/tensorflow.svg";
import Keras from   "../icons/keras.png";
import tailwindcss from "../icons/tailwindcss.svg"
import nextjs from "../icons/Next2.png"
import nodejs from "../icons/nodejs.png"
// IMAGES
import Aecor from '../images/Aecor.png'
import gfgproject from '../images/gfgproject.png'
import finkarma from '../images/finkarma.png'
import eazyoptions from '../images/eazyOptions.png'
import Portfolio from '../images/Portfolio.png'
import componentUI from '../images/componentUI.png'


const box = {
    initial: {
        height: "100vh",
        bottom: 0,
      },
      animate: {
        height: 0,
        transition: {
          when: "afterChildren",
          duration: 1.5,
          ease: [0.87, 0, 0.13, 1],
        },
      },
};
const textContainer = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: {
        duration: 0.25,
        when: "afterChildren",
      },
    },
  };
  const text = {
    initial: {
      y: 40,
    },
    animate: {
      y: 80,
      transition: {
        duration: 1.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
  };

export default function Projects() {

    //Vanilla JavaScript for Pop-up
    function displayFlex1(){
        document.querySelector('.bg-modal1').style.display = 'flex';
    }
    function displayFlex2(){
        document.querySelector('.bg-modal2').style.display = 'flex';
    }
    function displayFlex3(){
        document.querySelector('.bg-modal3').style.display = 'flex';
    }
    function displayFlex4(){
        document.querySelector('.bg-modal4').style.display = 'flex';
    }
    function displayFlex5(){
        document.querySelector('.bg-modal5').style.display = 'flex';
    }
    function displayFlex6(){
        document.querySelector('.bg-modal6').style.display = 'flex';
    }
    function displayNone1(){
        document.querySelector('.bg-modal1').style.display = 'none';
    }
    function displayNone2(){
        document.querySelector('.bg-modal2').style.display = 'none';
    }
    function displayNone3(){
        document.querySelector('.bg-modal3').style.display = 'none';
    }
    function displayNone4(){
        document.querySelector('.bg-modal4').style.display = 'none';
    }
    function displayNone5(){
        document.querySelector('.bg-modal5').style.display = 'none';
    }
    function displayNone6(){
        document.querySelector('.bg-modal6').style.display = 'none';
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const container = useRef(null)
    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector('#container'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../LottieAssets/project.json')
        })
    } , [])

    return (
        <div>
        <motion.div
            className="absolute z-50 flex items-center justify-center w-full bg-black"
            initial="initial"
            animate="animate"
            variants={box}
        >
            <motion.svg variants={textContainer}  className="absolute z-50 flex">
                <pattern
                    id="pattern"
                    patternUnits="userSpaceOnUse"
                    width={1000}
                    height={900}
                    className="text-white"
                >
                    <rect className="w-full h-full fill-current" />
                    <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
                </pattern>
                <text
                    className="text-3xl font-bold"
                    text-anchor="middle"
                    x="50%"
                    y="50%"
                    style={{ fill: "url(#pattern)" }}
                >
                    &#64;Ankit Project 
                </text>        
            </motion.svg>
        </motion.div>
        <Sidebar />
        <div className="home-section pb-20">
            <Navbar />
            <Particles color="#48CAE4"  num={200} type="cobweb" bg={true} />

            {/* HEADING SECTION */}
            <div className="projects-header flex flex-col-reverse md:flex-row">
            
                <div className="projects-Heading-text md:w-1/2 flex ml-12 md:ml-20 items-center">MY <br className="hidden md:block" /> WORKS</div>
                
                <div className="projects-Heading-image md:w-1/2 mt-20 flex justify-center items-center">
                    <div id = "container" ref={container}></div>
                </div>
            </div>
            
            {/* BODY SECTION */}
            <div className="flex flex-col md:flex-row pt-8 md:pt-28">
                <div className="md:w-1/2 flex flex-col items-center md:items-end">

                    {/* TODO:Card One Start */}
                    <div className="projectCard flex flex-row items-end" onClick={displayFlex1}  style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${Aecor})`}}>
                        <div className="w-1/2 text-white">Aecor</div>
                        <div className="w-1/2 flex flex-row justify-end mb-4">
                            <img src={javaScript} alt="techStack" className="mr-3" />
                            <img src={Python} alt="techStack" className="mr-3" />
                            <img src={tailwindcss} alt="techStack" className="mr-3" />
                            <img src={nextjs} alt="techStack" style={{width:"70px"}} />
                        </div>
                    </div>
                    {/* Card One Popup - this popup is only mobile responsive */}
                    <div className="bg-modal1">
                        <div className="Project-about flex flex-col md:flex-row justify-center">
                            <div className="md:w-1/2 md:pr-10">
                                <div className="flex justify-end md:px-5 mt-5 md:hidden" onClick={displayNone1}>
                                    <img src={cross} alt="cross" style={{width:"35px"}} />
                                </div>
                                <div className="h-1/2">
                                    <div className="projectImage" style={{backgroundImage: `url(${Aecor})`,backgroundSize :"cover",backgroundRepeat:"no-repeat",borderBottomRightRadius:"120px",height:"100%"}}></div>
                                </div>
                                <div className="h-1/2">
                                    <div className="TechStack-SubHeading mt-8 md:mt-10">TECH STACK</div>
                                    <div className="Popup-TechStack flex flex-row md:mt-5 justify-center items-center ">
                                        <img src={javaScript} alt="techStack" className="mr-3 w-6 h-6 md:w-14 md:h-14" />
                                        <img src={Python} alt="techStack" className="mr-3 w-6 h-6  md:w-14 md:h-14"/>
                                        <img src={tailwindcss} alt="techStack" className="mr-3 w-6 h-6  md:w-14 md:h-14"/>
                                        <img src={nextjs} alt="techStack" className="w-10 h-6 md:w-20 md:h-10" />
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 flex flex-col justify-between ">
                                <div className="md:flex justify-end px-5 mt-5 hidden md:block" onClick={displayNone1}>
                                    <img src={cross} alt="cross" style={{width:"25px"}} />
                                </div>
                                <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>This is the that monitors and provide <span className="text-green-300">Trading</span> support. The application has Server Side Rendering, also the application is supported for intallation in your local systems. i.e well known as <span className="text-green-300 text-2xl">PWA (Progressive Web Apps).</span> </div>
                                <div className="text-white text-base px-5 text-justify my-5" style={{color:"#B5B4B4"}}> The application is designed and built me and my friend <span className="text-green-300">@Ashish kumar mishra</span> </div>
                                <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>The application is built using a React.js framework know as <span className="text-green-300">Next.Js </span> for designing the UI I used <span className="text-green-300">Figma</span> & for styling the web application I used  <span className="text-green-300">Tailwind Css</span> </div>
                                <div className="flex flex-row justify-evenly my-5 md:my-10">
                                    <a href="https://aecorcap.com/" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">View Demo</a>
                                    <a href="https://github.com/AnkitRajCode/aecor" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">Get Code <i class="fab fa-github"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* TODO:Card Two Start */}
                    <div className="projectCard flex flex-row items-end mt-4" onClick={displayFlex2} style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${finkarma})`}}>
                        <div className="w-1/2 text-white">Finkarma </div>
                        <div className="w-1/2 flex flex-row justify-end mb-4">
                            <img src={javaScript} alt="techStack" className="mr-3" />
                            <img src={react} alt="techStack" className="mr-3" />
                            <img src={redux} alt="techStack" className="mr-3" />
                            <img src={Firebase} alt="techStack" />
                        </div>
                    </div>
                </div>
                {/* Card Two Popup */}
                <div className="bg-modal2">
                    <div className="Project-about flex flex-col md:flex-row justify-center">
                        <div className="md:w-1/2 md:pr-10">
                            <div className="flex justify-end md:px-5 mt-5 md:hidden" onClick={displayNone2}>
                                <img src={cross} alt="cross" style={{width:"35px"}} />
                            </div>
                            <div className="h-1/2">
                                <div className="projectImage" style={{backgroundImage: `url(${finkarma})`,backgroundSize :"cover",backgroundRepeat:"no-repeat",borderBottomRightRadius:"120px",height:"100%"}}></div>
                            </div>
                            <div className="h-1/2">
                                <div className="TechStack-SubHeading mt-8 md:mt-10">TECH STACK</div>
                                <div className="Popup-TechStack flex flex-row md:mt-5 justify-center items-center ">
                                    <img src={javaScript} alt="techStack" className="mr-3 w-6 h-6 md:w-10 md:h-10" />
                                    <img src={react} alt="techStack" className="mr-3 w-6 h-6  md:w-10 md:h-10"/>
                                    <img src={redux} alt="techStack" className="mr-3 w-6 h-6  md:w-10 md:h-10"/>
                                    <img src={Firebase} alt="techStack" className="mr-3 w-6 h-6  md:w-10 md:h-10"/>
                                    <img src={analytics} alt="techStack" className="mr-3 w-6 h-6  md:w-10 md:h-10"/>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 flex flex-col justify-between ">
                            <div className="md:flex justify-end px-5 mt-5 hidden md:block" onClick={displayNone2}>
                                <img src={cross} alt="cross" style={{width:"25px"}} />
                            </div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>I Design & build this project from <span className="text-green-300">Scratch</span>. Finkarma is a financial solution website which provide its service through blogs and personal consultancy.</div>
                            <div className="text-white text-base px-5 text-justify my-3" style={{color:"#B5B4B4"}}>I also integrated google analytics with this site according to that our website reach is <span className="text-green-300">5k+</span> in month</div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>The application is built using <span className="text-green-300">React.Js </span>, for database I use <span className='text-green-300'>Firebase</span>, for render data from database and process it I use <span className="text-green-300">Redux</span> & for Version control I use  <span className="text-green-300">Git and Github</span> </div>
                            <div className="flex flex-row justify-evenly my-5 md:my-10">
                                <a href="https://finkarma.netlify.app/" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">View Demo</a>
                                <a href="https://github.com/AnkitRajCode/Finkarma" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">Get Code <i class="fab fa-github"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* TODO:Card Three */}
                <div className="mt-4 md:mt-0 md:w-1/2">
                    <div className="projectCard-long flex flex-row items-end ml-5 md:ml-4" onClick={displayFlex3} style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${gfgproject})`}}>
                        <div className="w-1/2 text-white">GFG KIIT</div>
                        <div className="w-1/2 flex flex-row justify-end mb-4">
                            <img src={javaScript} alt="techStack" className="mr-3" />
                            <img src={react} alt="techStack" className="mr-3" />
                            <img src={css} alt="techStack" />
                        </div>
                    </div>
                </div>
                {/* Card Three Popup */}
                <div className="bg-modal3">
                    <div className="Project-about flex flex-col md:flex-row justify-center">
                        <div className="md:w-1/2 md:pr-10">
                            <div className="flex justify-end md:px-5 mt-5 md:hidden" onClick={displayNone3}>
                                <img src={cross} alt="cross" style={{width:"35px"}} />
                            </div>
                            <div className="h-1/2">
                                <div className="projectImage" style={{backgroundImage: `url(${gfgproject})`,backgroundSize :"cover",backgroundRepeat:"no-repeat",borderBottomRightRadius:"120px",height:"100%" ,backgroundPosition:"center"}}></div>
                            </div>
                            <div className="h-1/2">
                                <div className="TechStack-SubHeading mt-8 md:mt-10">TECH STACK</div>
                                <div className="Popup-TechStack flex flex-row md:mt-5 justify-center items-center ">
                                    <img src={javaScript} alt="techStack" className="mr-3 w-6 h-6 md:w-14 md:h-14" />
                                    <img src={react} alt="techStack" className="mr-3 w-6 h-6  md:w-14 md:h-14"/>
                                    <img src={css} alt="techStack" className="mr-3 w-6 h-6  md:w-14 md:h-14"/>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 flex flex-col justify-between ">
                            <div className="md:flex justify-end px-5 mt-5 hidden md:block" onClick={displayNone3}>
                                <img src={cross} alt="cross" style={{width:"25px"}} />
                            </div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>I was the Lead of the project when it was started from strach. I and my team took 2 months to complete the project. we were also appreciated for <span className="text-green-300">Best</span> website in geeksforgeeks club in India.</div>
                            <div className="text-white text-base px-5 text-justify my-5" style={{color:"#B5B4B4"}}>This Website is used as a <span className="text-green-300">Portfolio</span> of the GFG KIIT club. Here you will find Projects, Events & Current, and Ex-members of the club.</div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>The application is built using <span className="text-green-300">React.Js</span> for designing the UI my team use <span className="text-green-300">Adobe XD</span> & for styling the web application I and my Team prefer <span className="text-green-300">Custom Css</span>.</div>
                            <div className="flex flex-row justify-evenly my-5 md:my-10">
                                <a href="https://gfgkiit.netlify.app/" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">View Demo</a>
                                <a href="https://github.com/GFG-CLUB-KIIT/GFG-KIIT-website" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">Get Code <i class="fab fa-github"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Next Project Section */}
            <div className="flex flex-col md:flex-row-reverse mt-4">
                <div className="md:w-1/2 flex flex-col">

                    {/* TODO:Card Four Start */}
                    <div className="projectCard flex flex-row items-end ml-4" onClick={displayFlex4} style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${eazyoptions})`}}>
                        <div className="w-1/2 text-white">EazyOptions </div>
                        <div className="w-1/2 flex flex-row justify-end mb-2">
                            <img src={javaScript} alt="techStack" className="mr-3" />
                            <img src={react} alt="techStack" className="mr-3" />
                            <img src={bootstrap} alt="techStack" />
                        </div>
                    </div>
                    {/* Card Four Popup */}
                    <div className="bg-modal4">
                        <div className="Project-about flex flex-col md:flex-row justify-center">
                            <div className="md:w-1/2 md:pr-10">
                                <div className="flex justify-end md:px-5 mt-5 md:hidden" onClick={displayNone4}>
                                    <img src={cross} alt="cross" style={{width:"35px"}} />
                                </div>
                                <div className="h-1/2">
                                    <div className="projectImage" style={{backgroundImage: `url(${eazyoptions})`,backgroundSize :"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center",borderBottomRightRadius:"120px",height:"100%"}}></div>
                                </div>
                                <div className="h-1/2">
                                    <div className="TechStack-SubHeading mt-8 md:mt-10">TECH STACK</div>
                                    <div className="Popup-TechStack flex flex-row md:mt-5 justify-center items-center ">
                                        <img src={javaScript} alt="techStack" className="mr-5 w-6 h-6 md:w-14 md:h-14" />
                                        <img src={react} alt="techStack" className="mr-5 w-6 h-6  md:w-14 md:h-14"/>
                                        <img src={bootstrap} alt="techStack" className="mr-5 w-6 h-6  md:w-14 md:h-14"/>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 flex flex-col justify-between ">
                                <div className="md:flex justify-end px-5 mt-5 hidden md:block" onClick={displayNone4}>
                                    <img src={cross} alt="cross" style={{width:"25px"}} />
                                </div>
                                <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>I design & build websites from scratch. It is also Associated with finkarma fintech.</div>
                                <div className="text-white text-base px-5 text-justify my-5" style={{color:"#B5B4B4"}}>EazyOptions website deal with financial market help such as <span className="text-green-300">Straddle/Strangle</span>, <span className="text-green-300">Future Options</span> and many more. </div>
                                <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>The application is built using<span className="text-green-300">React.Js </span> for deploying I used <span className="text-green-300">Netlify</span> & for styling the web application I used  <span className="text-green-300">Bootstrap</span> </div>
                                <div className="flex flex-row justify-evenly my-5 md:my-10">
                                    <a href="https://eazyoptions.netlify.app/" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">View Demo</a>
                                    <a href="https://github.com/AnkitRajCode/EazyOptions" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">Get Code <i class="fab fa-github"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* TODO:Card five Start */}
                    <div className="projectCard flex flex-row items-end mt-4 ml-4" onClick={displayFlex5} style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${Portfolio})`}}>
                        <div className="w-1/2 text-white">Portfolio </div>
                        <div className="w-1/2 flex flex-row justify-end mb-2">
                            <img src={javaScript} alt="techStack" className="mr-3" />
                            <img src={react} alt="techStack" className="mr-3" />
                            <img src={tailwindcss} alt="techStack" />
                        </div>
                    </div>
                </div>
                {/* Card five Popup */}
                <div className="bg-modal5">
                    <div className="Project-about flex flex-col md:flex-row justify-center">
                        <div className="md:w-1/2 md:pr-10">
                            <div className="flex justify-end md:px-5 mt-5 md:hidden" onClick={displayNone5}>
                                <img src={cross} alt="cross" style={{width:"35px"}} />
                            </div>
                            <div className="h-1/2">
                                <div className="projectImage" style={{backgroundImage: `url(${Portfolio})`,backgroundSize :"cover",backgroundRepeat:"no-repeat",borderBottomRightRadius:"120px",height:"100%"}}></div>
                            </div>
                            <div className="h-1/2">
                                <div className="TechStack-SubHeading mt-8 md:mt-10">TECH STACK</div>
                                <div className="Popup-TechStack flex flex-row md:mt-5 justify-center items-center ">
                                    <img src={Figma} alt="techStack" className="mr-3 w-6 h-6 md:w-12 md:h-12" />
                                    <img src={javaScript} alt="techStack" className="mr-3 w-6 h-6 md:w-10 md:h-10" />
                                    <img src={react} alt="techStack" className="mr-3 w-6 h-6  md:w-10 md:h-10"/>
                                    <img src={tailwindcss} alt="techStack" className="mr-3 w-6 h-6  md:w-10 md:h-10"/>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 flex flex-col justify-between ">
                            <div className="md:flex justify-end px-5 mt-5 hidden md:block" onClick={displayNone5}>
                                <img src={cross} alt="cross" style={{width:"25px"}} />
                            </div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>Portfolio's are a great way to demonstrate the competencies we would list on our resume . Portfolio help us to showcase our<span className="text-green-300"> Work Potentials</span> and <span className="text-green-300">Learnings</span>.</div>
                            <div className="text-white text-base px-5 text-justify my-5" style={{color:"#B5B4B4"}}>I like to explore new projects and always like to contribute to them which help me to improve my <span className="text-green-300">Skills</span> Efficiently.</div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>The application is built using <span className="text-green-300">React.Js </span> for designing the UI I used <span className="text-green-300">Figma</span> & for styling the web application I used  <span className="text-green-300">Tailwind Css</span> </div>
                            <div className="flex flex-row justify-evenly my-5 md:my-10">
                                <a href="https://ankitraj.pages.dev/" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">View Demo</a>
                                <a href="https://github.com/AnkitRajCode/Portfolio" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">Get Code <i class="fab fa-github"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* TODO:Card six */}
                <div className="mt-4 md:mt-0 md:w-1/2 flex md:justify-end">
                    <div className="projectCard-long flex flex-row items-end ml-5 md:ml-4" onClick={displayFlex6} style={{backgroundImage: `linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%),url(${componentUI})`}}>
                        <div className="w-1/2 text-2xl md:text-4xl text-white">Next Gen UI</div>
                        <div className="w-1/2 flex flex-row justify-end mb-2">
                            <img src={nodejs} alt="techStack" className="mr-3" />
                            <img src={tailwindcss} alt="techStack" className="mr-3" />
                            <img src={nextjs} alt="techStack" style={{width:"70px"}} />
                        </div>
                    </div>
                </div>
                {/* Card six Popup */}
                <div className="bg-modal6">
                    <div className="Project-about flex flex-col md:flex-row justify-center">
                        <div className="md:w-1/2 md:pr-10">
                            <div className="flex justify-end md:px-5 mt-5 md:hidden" onClick={displayNone6}>
                                <img src={cross} alt="cross" style={{width:"35px"}} />
                            </div>
                            <div className="h-1/2">
                                <div className="projectImage" style={{backgroundImage: `url(${componentUI})`,backgroundSize :"contain",borderBottomRightRadius:"120px",height:"100%"}}></div>
                            </div>
                            <div className="h-1/2">
                                <div className="TechStack-SubHeading mt-8 md:mt-10">TECH STACK</div>
                                <div className="Popup-TechStack flex flex-row md:mt-5 justify-center items-center ">
                                    <img src={nextjs} alt="techStack" className="mr-3 w-10 h-6 md:w-20 md:h-10" />
                                    <img src={nodejs} alt="techStack" className="mr-3 w-6 h-6 md:w-10 md:h-10" />
                                    <img src={tailwindcss} alt="techStack" className="mr-3 w-6 h-6  md:w-14 md:h-14"/>
                                    <img src={analytics} alt="techStack" className="w-10 h-6 md:w-10 md:h-10" />
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 flex flex-col justify-between ">
                            <div className="md:flex justify-end px-5 mt-5 hidden md:block" onClick={displayNone6}>
                                <img src={cross} alt="cross" style={{width:"25px"}} />
                            </div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>Next Gen UI is a home for front-end & UI/Ux developers. We provide all the necessary components required to build a <span className="text-green-300">Scalable Front-end application</span> which suits the user experience in a unique way.</div>
                            <div className="text-white text-base px-5 text-justify my-5" style={{color:"#B5B4B4"}}>  The application has Server Side Rendering, also the application is supported for intallation in your local systems. i.e well known as <span className="text-green-300">PWA (Progressive Web Apps).</span> </div>
                            <div className="text-white text-base px-5 text-justify" style={{color:"#B5B4B4"}}>Made <span className="text-green-300">NPM Package</span>, which allows to import the prebulid front-end component. The Package is made using TypeScript, NodeJs, TSDX, StoryBook, MDX.</div>
                            <div className="flex flex-row justify-evenly my-5 md:my-10">
                                <a href="https://nextgenui.in/" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">View Demo</a>
                                <a href="https://github.com/ashishmishra-bit/next-gen-ui" target="_blank" rel="noreferrer" className="px-6 py-2 bg-white rounded-lg text-lg font-semibold">Get Code <i class="fab fa-github"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* BODY SECTION ENDS */}
        </div>
        <Footer />
    </div>
    )
}

