// Icons
import javaScript from "../icons/javaScript.svg"
import css from "../icons/css.svg"
import nextjs from "../icons/Next2.png"
import react from "../icons/react.png"
import tailwindcss from "../icons/tailwindcss.svg"
import python from "../icons/python.png"
import firebase from '../icons/firebase.png'
import vvrs from '../icons/vvrs.png'
import finkarmalogo from '../icons/finkarmalogo.png'
import Genesis from '../icons/genesis.png'
import acm from '../icons/acm.png'
import bps from '../icons/bps.png'
import KiitLogo from '../icons/KiitLogo.png'
//Images
import Aecor from '../images/Aecor.png'
import Gfgkiit from '../images/gfgkiit.png'
import gfgproject from '../images/gfgproject.png'
import HackerRankLogo from '../images/HackerRankLogo.png'
import finkarma from '../images/finkarma.png'
import Udemy from '../images/udemy.png'
import Learncodeonline from '../images/Learncodeonline.png'
import Geeksforgeeks from '../images/geeksforgeeks.png'

export default {
    // Home Page Data Start
    name: 'I am Ankit Raj',
    title: 'Full Stack Web Developer',

    about: 'I started web development 3 years ago. I basically use MERN Stack for my websites and I worked on various projects based on it.I like to explore new projects and always want to contribute to them which helps me grow my skills efficiently.My main focus is to learn algorithms and new technology and apply them in real-life projects which will enhance my skills.I was also part of some coding clubs of my institute such as ACM KIIT and GFG KIIT. At GFG KIIT I was head of web dev. domain where I and my teammates worked on various projects.',

    journeyExperienceHome: [
        {
            title: 'Web Developer(Finkarma)',
            date: 'July,2021 - December,2021',
            desc: 'I Design & Build their official website from Scratch using ReactJs and Firbase. Also worked on their sub-branch of financial Section, under which I worked on their EazyOptions website which deal with Straddle/Strangle, Future Options and many more.',
        },
    ],

    journeyEducationHome: [
        {
            title: 'B.Tech Information Technology (KIIT)',
            date: 'July-2019 - Present',
            desc: 'I am a 3rd year IT student , currently studying in Kalinga Institute of Industrial Technology, Bhubanserwar. I secured 8.89 CGPA in my 2nd Year. Also doing Freelancing side by side from 2nd year of my collage. Worked on various projects during these Couple of years.',
        },
    ],
    projectHome: [
        {
            title: 'Aecor',
            imgUrl: Aecor,

            techStack1: javaScript,
            techStack2: python,
            techStack3: tailwindcss,
            techStack4: nextjs,
        },
        {
            title: 'Finkarma',
            imgUrl: finkarma,
            techStack1: javaScript,
            techStack2: react,
            techStack3: firebase,
        },

    ],
    longProjectCardHome: [
        {
            title: `GFG KIIT`,
            imgUrl: gfgproject,
            techStack1: javaScript,
            techStack2: react,
            techStack3: css,
        }
    ],
    // Home Page Data End============================================================

    //Journey Data Start=============================================================
    experiences: [
        {
            title: 'Web Developer (Finkarma)',
            date: 'July-2021 - December-2021',
            desc: 'This was my first paid freelance work .I Design & Build their official website from Scratch using ReactJs and Firbase. Also worked on their sub-branch of financial Section EazyOptions website which deal with Straddle/Strangle, Future Options and many more.',
            imgUrl: finkarmalogo,
            url: "https://finkarma.in"
        },
        {
            title: 'Lead Web Dev. (Geeksforgeeks KIIT)',
            date: 'October-2020 - July-2021',
            desc: 'At GFG KIIT I was head of web dev. domain, where I and my teammates worked on various projects. But Primary Project was Geeksforgeeks KIIT official website which was develop under my supervision and also I was major contributor of that project.',
            imgUrl: Gfgkiit,
            url: "https://gfgkiit.co"
        },
        {
            title: 'Founder (Genesis)',
            date: 'June-2020 - Present',
            desc: 'I founded this club at my collage to give talented students a platform to present there talent. In this club under my supervision successful recruitment took place in all domain that we serve, and also from our club some of students we selected for collage events.',
            imgUrl: Genesis,
            url: "https://instagram.com/genesis.journey?igshid=1lbfzyfu3zztj"
        },
        {
            title: 'Competetive Programmmer (KIIT ACM)',
            date: 'Feb-2020 - June-2020',
            desc: 'I was in there CP(Competetive Programming) domain. Their I participated in ACM weekly challenges which was benificial for me in terms of understand DSA and other stuffs',
            imgUrl: acm,
            url: "https://instagram.com/kiitacm?igshid=yv6pu5g8l5ua"
        },
    ],

    educations: [
        {
            title: 'B.Tech Information Technology (KIIT)',
            date: 'July-2019 - Present',
            desc: 'I am a 3rd year IT student , currently studying in Kalinga Institute of Industrial Technology, Bhubanserwar. I secured 8.89 CGPA in my 2nd Year. Also doing Freelancing side by side from 2nd year of my collage. Worked on various projects during these Couple of years.',
            imgUrl: KiitLogo,
            url: 'https://kiit.ac.in/'
        },
        {
            title: 'Senior Secondary - { XII } (VVRS) ',
            date: '05/2016 - 05/2018',
            desc: 'I studied in Vidya Vihar Residential School, Purnea; Its an CBSE board school with all facilities. I secured 73.4% in my XIIth grade and my subjects were Physics, Chemistry, Maths, English & Information Pratices.',
            imgUrl: vvrs,
            url: 'https://www.vidyavihar.org//'
        },
        {
            title: 'Secondary  - { X } (BPS) ',
            date: '05/2014 - 05/2016',
            desc: 'I studied in Bijendra Public School, Purnea; Its an CBSE board school with all facilities. I secured 10 CGPA(i.e. 95%) in my Xth grade and my subjects were English, Maths, Science, Social Science & Sanskrit.',
            imgUrl: bps,
            url: 'https://bpspurnea.com/'
        },

    ],

    //Journey Data End

    //Certifications section start
    certifications: [
        {
            imgUrl : Geeksforgeeks,                      
            title:"Web Development Lead Certificate",
            organization:"Geeksforgeeks",
            date:"Session 2020-21",
            link:"https://drive.google.com/file/d/1TTGRI2AzBL_3gYKKtnOnh9yId2DWm_5P/view?usp=sharing",
        },
        {
            imgUrl : Udemy,                      
            title:"Linux Command Line",
            organization:"Udemy",
            date:"Issue 26 Jan, 2022 - No expiration date",
            link:"https://www.udemy.com/certificate/UC-ccf367dd-b692-405e-94c2-b67eeebd693d/",
        },
        {
            imgUrl : Learncodeonline,                      
            title:"MERN Certificate",
            organization:"Learn Code Online",
            date:"Issue 27 Dec, 2021 - No expiration date",
            link:"https://drive.google.com/file/d/16q_hy_uXJ3_Wx2DnA3NZALC5zqLaSTZs/view?usp=sharing",
        },
        {
            imgUrl :HackerRankLogo,
            title:"React (Basic) Certificate",
            organization:"HackerRank",
            date:"Issue 05 Dec , 2021 - No expiration date",
            link:"https://www.hackerrank.com/certificates/e5e70b47e021",
        },
        {
            imgUrl :HackerRankLogo,
            title:"Python (Basic) Certificate",
            organization:"HackerRank",
            date:"Issue 05 Dec , 2021 - No expiration date",
            link:"https://www.hackerrank.com/certificates/9ed4dad1f2eb",
        },
        {
            imgUrl :HackerRankLogo,
            title:"SQL (Basic) Certificate",
            organization:"HackerRank",
            date:"Issue 01 Dec , 2021 - No expiration date",
            link:"https://www.hackerrank.com/certificates/7c9a2f88ebb6",
        },
        {
            imgUrl :HackerRankLogo,
            title:"Cascading Style Sheets(CSS) Certificate",
            organization:"HackerRank",
            date:"Issue 01 Dec , 2021 - No expiration date",
            link:"https://www.hackerrank.com/certificates/6dc95524000f",
        },
        {
            imgUrl :HackerRankLogo,
            title:"Problem Solving (Intermediate) Certificate",
            organization:"HackerRank",
            date:"Issue 28 September , 2021 - No expiration date",
            link:"https://www.hackerrank.com/certificates/63de1ea2795b",
        },
        {
            imgUrl:  HackerRankLogo,
            title: "Problem Solving (Basic) Certificate",
            organization: "HackerRank",
            date: "Issue 28 September , 2021 - No expiration date",
            link: "https://www.hackerrank.com/certificates/a8ff6364e742",
        },
        {
            imgUrl : Udemy,                      
            title:"Git and Github",
            organization:"Udemy",
            date:"Issue July , 2021 - No expiration date",
            link:"https://www.udemy.com/certificate/UC-50316e1b-2a38-44c5-835b-5fac1f930c9e/",
        },
        {
            imgUrl : Udemy,                      
            title:"HTML, CSS, & JavaScript",
            organization:"Udemy",
            date:"Issue May , 2020 - No expiration date",
            link:"https://www.udemy.com/certificate/UC-a0bf1ac0-f4f3-4c48-9a69-d45c2f40fdff/",
        },
        
    ],
};
