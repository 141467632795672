import React,{ useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
// Component
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// Icons
import profile from '../icons/profile.svg'
import Calander from '../icons/Calander.svg'
// Image
import web from '../images/web.jpeg'
// CSS
import '../css/SeePost.css'

const box = {
    initial: {
        height: "100vh",
        bottom: 0,
    },
    animate: {
        height: 0,
        transition: {
            when: "afterChildren",
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
const textContainer = {
    initial: {
        opacity: 1,
    },
    animate: {
        opacity: 0,
        transition: {
            duration: 0.25,
            when: "afterChildren",
        },
    },
};
const text = {
    initial: {
        y: 40,
    },
    animate: {
        y: 80,
        transition: {
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};

export default function SeePost() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div>
            <motion.div
                className="absolute z-50 flex items-center justify-center w-full bg-black"
                initial="initial"
                animate="animate"
                variants={box}
            >
                <motion.svg variants={textContainer} className="absolute z-50 flex">
                    <pattern
                        id="pattern"
                        patternUnits="userSpaceOnUse"
                        width={750}
                        height={800}
                        className="text-white"
                    >
                        <rect className="w-full h-full fill-current" />
                        <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
                    </pattern>
                    <text
                        className="text-4xl font-bold"
                        text-anchor="middle"
                        x="50%"
                        y="50%"
                        style={{ fill: "url(#pattern)" }}
                    >
                        &#64;Ankit Blog
                    </text>

                </motion.svg>
            </motion.div>
            <Sidebar />
            <div className="home-section">
              <Navbar />
              <div className="py-20 px-5 md:px-36" style={{ color: "#9A9A9A" }}>
                <img src={"https://miro.medium.com/max/1600/1*dM5P75FLQnWxxVLze35NCg.jpeg"} alt="BlogImage" style={{width:"100%",borderRadius:"20px"}} />

                  <div className="Blog-Body -mt-2 md:-mt-40 md:p-20 md:mx-10">
                    <div className="Blog-Title pt-5 md:pt-0 mb-10">
                        Authentication API with JWT Token
                    </div>

                    <div className="flex flex-col md:flex-row items-center text-lg font-semibold">
                        <div className="md:w-1/2 flex flex-row items-center justify-end md:mr-3"><img src={profile} alt="profile" style={{ width: "20px" }} /> <span className="ml-3">Ankit Raj</span> </div> <span className="hidden md:block">|</span>
                        <div className="md:w-1/2 flex flex-row items-center md:ml-3 mt-5 md:mt-0"><img src={Calander} alt="Calander" style={{ width: "20px" }} /> <span className="ml-3">Date - 21st Nov, 2021</span> </div>
                    </div>

                      <div className="px-5 md:px-16 text-justify my-10">
                        <h1 className="font-bold text-xl md:text-2xl text-white">Introduction</h1>
                        <h1 className="font-bold text-xl md:text-2xl mt-5">What is JWT?</h1>
                        <div className="mb-3 leading-10 text-lg">
                          <p className="pb-3">A JWT is a mechanism to verify the owner of some JSON data. It’s an encoded, URL-safe string that can contain an unlimited amount of data (unlike a cookie) and is cryptographically signed.When a server receives a JWT, it can guarantee the data it contains can be trusted because it’s signed by the source. No middleman can modify a JWT once it’s sent.</p>
                          <p>It’s important to note that a JWT guarantees data ownership but not encryption. The JSON data you store into a JWT can be seen by anyone that intercepts the token because it’s just serialized, not encrypted.For this reason, it’s highly recommended to use HTTPS with JWTs (and HTTPS in general, by the way).We’re not going to cover how JWTs are generated in detail. For an in-depth, up-to-date look at how JWT authentication works, check out “JWT authentication from scratch with Vue.js and Node.js.”</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">When to use JWT authentication</h1>
                        <div className="my-3 leading-10 text-lg ">
                            <p>JWT is a particularly useful technology for API authentication and server-to-server authorization.For a comprehensive guide on using JWT technology to authenticate APIs, check out “How to secure a REST API using JWT.”</p>
                        </div>
                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Why you shouldn’t use JWTs as session tokens</h1>
                        <div className="my-3 leading-10 text-lg ">
                            <p>On the other hand, you should not use JWTs as session tokens by default. For one thing, JWT has a wide range of features and a large scope, which increases the potential for mistakes, either by library authors or users.Another issue is that you can’t remove a JWT at the end of a session because it’s self-contained and there’s no central authority to invalidate them.Finally, to put it simply, JWTs are relatively large. When used with cookies, this adds up to a ton of overhead per request.</p>
                            <p>Using JWTs for session tokens might seem like a good idea at first because:                          </p>
                            • You can store any kind of user details on the client <br/>
                            • The server can trust the client because the JWT is signed, and there is no need to call the database to retrieve the information you already stored in the JWT <br/>
                            • You don’t need to coordinate sessions in a centralized database when you get to the eventual problem of horizontal scaling 
                            <p>Ultimately, if you already have a database for your application, just use a sessions table and use regular sessions as provided by the server-side framework of choice.Why? There is a cost involved in using JWTs: they are sent for every request to the server and it’s always a high cost compared to server-side sessions.Also, while the security risks are minimized sending JWTs using HTTPS, there is always the possibility that it’s intercepted and the data deciphered, exposing your user’s data.</p>
                        </div>
                        
                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Using JWT for API authentication</h1>
                        <div className="my-3 leading-10 text-lg ">
                            <p>A very common use for JWT — and perhaps the only good one — is as an API authentication mechanism.JWT technology is so popular and widely used that Google uses it to let you authenticate to its APIs.</p>
                            <p>The idea is simple: you get a secret token from the service when you set up the API:</p>
                            <img src="https://imgur.com/ZwW7nGI.png" alt="blog" />
                            <p className='mt-3'>On the client side, you create the token (there are many libraries for this) using the secret token to sign it.</p>
                            <p>When you pass it as part of the API request, the server will know it’s that specific client because the request is signed with its unique identifier:</p>
                            <img src="https://imgur.com/o1lmF8o.png" alt="blog" />
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">How to expire a single JWT token</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p className="pb-3">How do you invalidate a single token? A no-effort solution is to change the server secret key, which invalidates all tokens. However, this is not ideal for users, who may have their tokens expired for no reason.One way to do it is to add a property to your user object in the server database to reference the date and time at which the token was created.</p>
                          <p >A token automatically stores this value in the iat property. Every time you check the token, you can compare its iat value with the server-side user property.To invalidate the token, just update the server-side value. If iat is older than this, you can reject the token.Another way to achieve this is by establishing a blacklist in your database cached in memory (or, even better, a whitelist).</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">How to securely store JWTs in a cookie</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p className="pb-3">A JWT needs to be stored in a safe place inside the user’s browser. If you store it inside localStorage, it’s accessible by any script inside your page. This is as bad as it sounds; an XSS attack could give an external attacker access to the token.</p>
                          <p>To reiterate, whatever you do, don’t store a JWT in local storage (or session storage). If any of the third-party scripts you include in your page is compromised, it can access all your users’ tokens.To keep them secure, you should always store JWTs inside an httpOnly cookie. This is a special kind of cookie that’s only sent in HTTP requests to the server. It’s never accessible (both for reading or writing) from JavaScript running in the browser.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Using JWT for SPA authentication</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p>JWTs can be used as an authentication mechanism that does not require a database. The server can avoid using a database because the data store in the JWT sent to the client is safe.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Using JWT to authorize operations across servers</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p>Say you have one server where you are logged in, SERVER1, which redirects you to another server SERVER2 to perform some kind of operation.SERVER1 can issue you a JWT that authorizes you to SERVER2. Those two servers don’t need to share a session or anything to authenticate you. The token is perfect for this use case.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10">Conclusion</h1>
                        <div className="my-3 leading-10 text-lg ">
                          <p>JWT is a very popular standard you can use to trust requests by using signatures, and exchange information between parties. Make sure you know when it’s best used, when it’s best to use something else, and how to prevent the most basic security issues.</p>
                        </div>

                        <h1 className="font-bold text-xl md:text-2xl text-white mt-10 text-center pb-10 md:pb-0">Thank You For Reading</h1>
                      </div>
                    </div>
                </div>

                {/* Other BLOG */}
                <div className="flex flex-col md:flex-row px-5 md:px-36 my-20">
                  <Link to="/blogs/web-accessibility" className="md:w-1/2">
                    <img src={"https://altitudemarketing.com/wp-content/uploads/2019/11/coding-graphic-min.png"} alt="BlogImage" style={{ width: "550px" , borderRadius: "31px"}} />
                  </Link>
                  <div className="md:w-1/2 flex flex-col justify-between md:ml-10" style={{ color: "#9A9A9A" }}>
                    <div className="text-lg mt-5 md:mt-0">Date : 21st jan , 2021</div>
                    <Link to="/blogs/web-accessibility"><div className="text-2xl font-semibold my-2">A Beginner's Guide to Web Accessibility</div></Link>
                    <div className="text-lg my-2 text-justify">Web accessibility means that websites, tools, and technologies are designed and developed so that people with disabilities can use themMore specifically, people can perceive, understand, navigate, and interact with the Web contribute to the Web...</div>
                    <Link to="/blogs/web-accessibility" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
                  </div>
                </div>

                {/* <h1 className="font-bold text-2xl text-white mb-10">Sentiment analysis</h1>
                            <div className="flex flex-col items-center">
                                <img src={"https://storage.ning.com/topology/rest/1.0/file/get/7316024065?profile=original"} alt="BlogImage2" style={{ width: "700px" }} />
                                <p className="text-sm font-semibold mt-1">Fig1 - Tensorflow and Keras</p>
                            </div> */}
                {/* FOOTER */}
                <Footer />
            </div>
        </div>
    )
}
