import React,{ useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
// Component
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// Icons
import profile from '../icons/profile.svg'
import Calander from '../icons/Calander.svg'
// CSS
import '../css/SeePost.css'

const box = {
    initial: {
        height: "100vh",
        bottom: 0,
    },
    animate: {
        height: 0,
        transition: {
            when: "afterChildren",
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
const textContainer = {
    initial: {
        opacity: 1,
    },
    animate: {
        opacity: 0,
        transition: {
            duration: 0.25,
            when: "afterChildren",
        },
    },
};
const text = {
    initial: {
        y: 40,
    },
    animate: {
        y: 80,
        transition: {
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};

export default function SeePost() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div>
            <motion.div
                className="absolute z-50 flex items-center justify-center w-full bg-black"
                initial="initial"
                animate="animate"
                variants={box}
            >
                <motion.svg variants={textContainer} className="absolute z-50 flex">
                    <pattern
                        id="pattern"
                        patternUnits="userSpaceOnUse"
                        width={750}
                        height={800}
                        className="text-white"
                    >
                        <rect className="w-full h-full fill-current" />
                        <motion.rect variants={text} className="w-full h-full text-gray-600 fill-current" />
                    </pattern>
                    <text
                        className="text-4xl font-bold"
                        text-anchor="middle"
                        x="50%"
                        y="50%"
                        style={{ fill: "url(#pattern)" }}
                    >
                        &#64;Ankit Blog
                    </text>

                </motion.svg>
            </motion.div>
            <Sidebar />
            <div className="home-section">
              <Navbar />
              <div className="py-20 px-5 md:px-36" style={{ color: "#9A9A9A" }}>
                <img src={"https://letscodee.com/wp-content/uploads/2021/06/html_to_drive-1536x864.png"} alt="BlogImage" style={{width:"100%",borderRadius:"20px"}} />

                  <div className="Blog-Body -mt-2 md:-mt-40 md:p-20 md:mx-10">
                    <div className="Blog-Title pt-5 md:pt-0 mb-10">
                        Submit the HTML form data in Google Sheet
                    </div>

                    <div className="flex flex-col md:flex-row items-center text-lg font-semibold">
                        <div className="md:w-1/2 flex flex-row items-center justify-end md:mr-3"><img src={profile} alt="profile" style={{ width: "20px" }} /> <span className="ml-3">Ankit Raj</span> </div> <span className="hidden md:block">|</span>
                        <div className="md:w-1/2 flex flex-row items-center md:ml-3 mt-5 md:mt-0"><img src={Calander} alt="Calander" style={{ width: "20px" }} /> <span className="ml-3">Date - Coming Soon</span> </div>
                    </div>

                      <div className="px-5 md:px-16 text-justify my-10">
                        <h1 className="font-bold text-xl md:text-5xl text-white mt-10 text-center pb-10 md:pb-0">Coming Soon...</h1>
                      </div>
                    </div>
                </div>

                {/* Other BLOG */}
                <div className="flex flex-col md:flex-row px-5 md:px-36 my-20">
                  <Link to="/blogs/web-accessibility" className="md:w-1/2">
                    <img src={"https://altitudemarketing.com/wp-content/uploads/2019/11/coding-graphic-min.png"} alt="BlogImage" style={{ width: "550px" , borderRadius: "31px"}} />
                  </Link>
                  <div className="md:w-1/2 flex flex-col justify-between md:ml-10" style={{ color: "#9A9A9A" }}>
                    <div className="text-lg mt-5 md:mt-0">Date : 21st jan , 2021</div>
                    <Link to="/blogs/web-accessibility"><div className="text-2xl font-semibold my-2">A Beginner's Guide to Web Accessibility</div></Link>
                    <div className="text-lg my-2 text-justify">Web accessibility means that websites, tools, and technologies are designed and developed so that people with disabilities can use themMore specifically, people can perceive, understand, navigate, and interact with the Web contribute to the Web...</div>
                    <Link to="/blogs/web-accessibility" className="text-lg" style={{ color: "#5AE86B" }}>Read More &#x203A; &#x203A; &#x203A;</Link>
                  </div>
                </div>

                {/* FOOTER */}
                <Footer />
            </div>
        </div>
    )
}
