import React from 'react';
import { Switch, Route } from 'react-router';
import Blogs from '../Pages/Blogs';
import Journey from '../Pages/Journey';
import Landing from '../Pages/Landing'
import Projects from '../Pages/Projects';
import SeePost from '../Pages/SeePost';
import SeePost2 from '../Pages/SeePost2'
import SeePost3 from '../Pages/SeePost3'
import SeePost4 from '../Pages/SeePost4'
import Achievements from '../Pages/Achievements';

const Routes = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/journey" component={Journey} />
                <Route exact path="/achievements" component={Achievements} />
                <Route exact path="/projects" component={Projects} />
                <Route exact path="/blogs" component={Blogs} />
                <Route exact path="/blogs/web-accessibility" component={SeePost} />
                <Route exact path="/blogs/frontend-developers-should-learn-firebase" component={SeePost2} />
                <Route exact path="/blogs/jwt" component={SeePost3} />
                <Route exact path="/blogs/submit-the-html-form-data-in-google-sheet" component={SeePost4} />
            </Switch>
        </>
    );
}

export default Routes;